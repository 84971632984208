<template>
  <form
    :class="{'was-validated': $v.items.$dirty}"
    class="custom-form"
    @submit.prevent="submit"
  >

    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10">
        <div class="row">
          <!-- Article Dropdown -->
          <div class="form-group col-12 col-md-12">
            <label class="form-label mb-3">
              Article <span class="text-danger">*</span>
            </label>
            <autocomplete
              :list="{items: articles}"
              :multiple="false"
              :customClass="{'is-invalid': $v.items.articleId.$error}"
              placeholder="Select Article"
              label="customName"
              local
              @input="newValue => selectArticle(newValue)"
            />
            <template v-if="$v.items.articleId.$dirty">
              <div v-if="!$v.items.articleId.required" class="invalid-feedback d-block">
                Article is required
              </div>
            </template>
          </div>
          <!-- Article Dropdown End-->
        </div>

        <div class="row mt-3">

          <!-- Supplier Dropdown -->
          <div v-if="!isCash" class="form-group  col-12 col-md-6">
            <label class="form-label mb-3">
              Supplier <span class="text-danger">*</span>
            </label>

            <select
              v-model="items.selectedReplenishment.id"
              :class="{'is-invalid': $v.items.selectedReplenishment.$error}"
              class="form-control custom-select"
              @change="selectSupplier"
            >
              <option value="">Select Supplier</option>
              <template v-if="items.articleId">
                <option
                  v-for="replenishment in selectedArticle.replenishments"
                  :key="replenishment.id"
                  :value="replenishment.id">
                  <template v-if="replenishment.is_preferred_supplier">(Preferred)</template>
                  {{replenishment.supplier.name}}
                </option>
              </template>
            </select>

            <template v-if="$v.items.selectedReplenishment.$dirty">
              <div v-if="!$v.items.selectedReplenishment.required" class="invalid-feedback d-block">
                Supplier is required
              </div>
            </template>
          </div>
          <!-- End Suplier Drodown -->

          <!-- Delivery Date Dropdown -->
          <div
            class="form-group col-12"
            :class="{'offset-md-1 col-md-5': !isCash}">
            <label
              class="
            form-label
            mb-3">
              Delivery Date <span class="text-danger">*</span>
            </label>
            <input
              v-model="items.deliveryDate"
              :class="{'is-invalid': $v.items.deliveryDate.$error}"
              type="date"
              class="form-control"
              :min="minDate"
            />
            <template v-if="$v.items.deliveryDate.$dirty">
              <div v-if="!$v.items.deliveryDate.required" class="invalid-feedback d-block">
                Delivery Date is required
              </div>
            </template>
          </div>
          <!-- End Delivery Date Dropdown -->
        </div>

        <div class="row mt-3">

          <!-- UOM Dropdown -->
          <div class="form-group col-12 col-md-6">
            <label class="form-label mb-3">
              UOM <span class="text-danger">*</span>
            </label>
            <unit-of-measures-dropdown
              :filter="{is_for_oum: true}"
              :value="items.selectedReplenishment.ordering_uom_id"
              :hasError="$v.items.selectedReplenishment.ordering_uom_id.$error ? true : false"
              :required="true"
              @input="selectUnitOfMeasure"
            />
            <template v-if="$v.items.selectedReplenishment.ordering_uom_id.$dirty">
              <div v-if="!$v.items.selectedReplenishment.ordering_uom_id.required" class="invalid-feedback d-block">
                UOM is required
              </div>
            </template>
          </div>
          <!-- UOM Dropdown End -->

          <!-- Quantity Input -->
          <div class="form-group offset-md-1 col-12 col-md-5">
            <label class="form-label mb-3">
              Quantity <span class="text-danger">*</span>
            </label>
            <input
              v-model.number="items.quantity"
              type="number"
              class="form-control"
              required
              min="1"
              @input="onQuantityChange"/>
            <template v-if="$v.items.quantity.$dirty">
              <div v-if="!$v.items.quantity.required" class="invalid-feedback d-block">
                Quantity is required
              </div>
            </template>
          </div>
          <!-- Quantity Input End -->

          <!-- Price Input -->
          <div v-if="!isCash" class="form-group col-12 col-md-6 mt-3">
            <label class="form-label mb-3">
              Price <span class="text-danger">*</span>
            </label>
            <input
              v-model.number="items.selectedReplenishment.cost_price"
              type="number"
              class="form-control"
              disabled
              min="1"
              step="any"
              @input="onPriceChange"
            />
            <template v-if="$v.items.selectedReplenishment.cost_price.$dirty">
              <div v-if="!$v.items.selectedReplenishment.cost_price.required" class="invalid-feedback d-block">
                Price is required
              </div>
            </template>
          </div>
          <!-- Price Input End -->

        </div>

        <div class="row">
          <div class="col-12">
            <button type="submit" class="float-right btn">Add Item</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import Autocomplete from '@/components/Autocomplete';
import UnitOfMeasuresDropdown from '@/components/filters/UnitOfMeasuresDropdown';
import moment from 'moment-timezone';

export default {
  components: {

    Autocomplete,
    UnitOfMeasuresDropdown,
  },
  props: {

    articles: {type: Array, default: () => []},
    isCash: {type: Boolean, default: false},
  },
  data() {
    return {
      minDate: moment().format('YYYY-MM-DD'),
      selectedArticle: {},
      initialPrice: null,
      items: {
        articleId: null,
        articleName: '',
        categoryId: null,
        deliveryDate: moment().add(24, 'hours').format('YYYY-MM-DD'),
        quantity: 1,
        replenishments: [],
        selectedReplenishment: {},
      },
    };
  },
  validations: {
    items: {
      articleId: {
        required,
      },
      deliveryDate: {
        required,
      },
      quantity: {
        required,
      },
      selectedReplenishment: {
        supplier_id: {
          required,
        },
        ordering_uom_id: {
          required,
        },
        cost_price: {
          required,
        },
      },
    },
  },
  methods: {
    selectArticle(article) {
      this.selectedArticle = article;
      this.items.articleId = article ? article.id : null;
      this.items.articleName = article ? article.name : null;
      this.items.categoryId = article ? article.ingredient?.category_id : null;
      this.items.quantity = 1;
      this.items.replenishments = this.selectedArticle.replenishments;
      this.items.maxShelfLife = article.shelf_life_expiration_date;

      this.items.selectedReplenishment = {
        ...this.selectedArticle.replenishments[0],
        cost_price: parseFloat(this.selectedArticle.replenishments[0].cost_price).toFixed(2) * 1,
      };
      this.initialPrice = this.items.selectedReplenishment.cost_price;
    },

    selectSupplier(event) {
      const replenishmentId = parseInt(event.target.value);

      if (!replenishmentId) {
        return;
      }

      const selected = this.items.replenishments.find(({id}) => id === replenishmentId);

      if (selected) {
        Object.assign(this.items.selectedReplenishment, selected, {
          cost_price: parseFloat(selected.cost_price).toFixed(2) * 1,
        });
        this.items.quantity = 1;
        this.initialPrice = this.items.selectedReplenishment.cost_price;
      }
    },

    selectUnitOfMeasure(unitOfMeasureId) {
      this.items.selectedReplenishment.ordering_uom_id = unitOfMeasureId;
    },

    onQuantityChange(event) {
      const quantity = event.target.value;
      if (this.initialPrice && quantity) {
        this.items.selectedReplenishment.cost_price = parseFloat(this.initialPrice * quantity).toFixed(2) * 1;
      }
    },

    onPriceChange() {
      this.initialPrice = null;
    },

    submit() {
      this.$v.items.$touch();

      if (!this.$v.items.$invalid) {
        this.$emit('submit', this.items);
      }
    },
  },
};
</script>
