<template>
  <table class="table table-outline table-bordered table-striped table-hover table-vcenter card-table dataTables_wrapper">
    <thead class="thead-light leading-none text-center">
      <tr>
        <th scope="col">Article ID</th>
        <th scope="col">Article Name</th>
        <th v-if="!isCash" scope="col">Supplier</th>
        <th scope="col">Quantity</th>
        <th
          v-b-tooltip.hover
          scope="col"
          title="Ordering unit of measure"
          class="cursor-pointer">
          OUM <i class="fe fe-help-circle text-muted"></i>
        </th>
        <th v-if="!isCash" scope="col">Net Price</th>
        <th scope="col">Delivery Date</th>
        <th v-if="isCash" scope="col">Min Allowed Shelf Life</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(article, index) in articles"
        :key="index"
        class="tr-height">
        <th>{{article.articleId}}</th>
        <th>{{article.articleName}}</th>
        <th v-if="!isCash">
          <select
            v-model="article.selectedReplenishment.id"
            class="form-control custom-select"
            required
            @change="event => selectSupplier(event, index)"
          >
            <option
              v-for="replenishment in article.replenishments"
              :key="replenishment.id"
              :value="replenishment.id"
            >
              {{replenishment.supplier.name}}
            </option>
          </select>
        </th>
        <th>
          <input
            v-model.number="article.quantity"
            type="number"
            placeholder="Quantity"
            class="form-control"
            min=1
          />
        </th>
        <th>
          <unit-of-measures-dropdown
            :filter="{is_for_oum: true}"
            :value="article.selectedReplenishment.ordering_uom_id"
            :required="true"
            @input="unitOfMeasureId => selectUnitOfMeasure(unitOfMeasureId, index)"
          />
        </th>
        <th v-if="!isCash">
          <input
            v-model.number="article.selectedReplenishment.cost_price"
            disabled
            type="number"
            step="any"
            placeholder="Price"
            class="form-control"/>
        </th>
        <th>
          <input
            v-model="article.deliveryDate"
            type="date"
            class="form-control"
            :min="minDate"
          />
        </th>
        <th v-if="isCash" class="text-center">{{calculateMaxShelfLife(article)}}</th>
        <th>
          <i
            class="fe fe-trash delete-icon"
            @click="() => $emit('delete', index)"
          ></i>
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
import moment from 'moment-timezone';
import UnitOfMeasuresDropdown from '@/components/filters/UnitOfMeasuresDropdown';

export default {
  components: {

    UnitOfMeasuresDropdown,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
    isCash: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minDate: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    calculateMaxShelfLife() {
      return article => parseInt(article.maxShelfLife) > 0 ? (moment().add(article.maxShelfLife, 'days').format('DD-MM-YYYY')) : '-';
    },
  },
  methods: {
    selectSupplier(event, articleIndex) {
      const replenishmentId = parseInt(event.target.value);
      const selected = this.articles[articleIndex].replenishments.find(replenishment => replenishment.id === replenishmentId);

      if (selected) {
        Object.assign(this.articles[articleIndex].selectedReplenishment, selected, {
          cost_price: parseFloat(selected.cost_price),
        });

        this.articles[articleIndex].quantity = 1;
      }
    },
    selectUnitOfMeasure(unitOfMeasureId, articleIndex) {
      this.articles[articleIndex].selectedReplenishment.ordering_uom_id = unitOfMeasureId;
    },
  },
};
</script>

<style scoped>
  .table th {
    color: #000000;
  }

  .tr-height {
    height: 150px;
  }

  .delete-icon {
    color: #8B0000;
    font-size: 20px;
  }

  .delete-icon:hover {
    cursor: pointer;
  }

</style>
