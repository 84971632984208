<template>
  <select
    v-model="selectedValue"
    :class="{'is-invalid': hasError }"
    :required="required"
    class="form-control custom-select"
  >
    <option :value="null">Select UOM</option>
    <option
      v-for="unitOfMeasure in unitOfMeasures"
      :key="unitOfMeasure.id"
      :value="unitOfMeasure.id">
      {{unitOfMeasure.name}}
    </option>
  </select>
</template>
<script>
import {mapActions, mapState} from 'vuex';

export default {
  props: {
    filter: {
      required: false,
      type: Object,
    },
    required: {
      default: false,
      type: Boolean,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapState('uom', ['uoms']),
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    unitOfMeasures() {
      if (!this.filter || Object.keys(this.filter).length === 0) {
        return this.uoms;
      }

      return this.uoms.filter(unitOfMeasure => {
        for (const key in this.filter) {
          if (unitOfMeasure[key] !== this.filter[key]) {
            return false;
          }
        }

        return true;
      });
    },
  },
  created() {
    this.getUoms();
  },
  methods: {
    ...mapActions('uom', ['getUoms']),
  },
};
</script>
