<template>
  <div>
    <template v-if="edit">
      <form :class="{'was-validated': $v.filter.$dirty}" @submit.prevent="validate">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label form-label text-right">Weekly Menu</label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.weeklyMenu.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.filter.weeklyMenu.$error}">
              <option :value="null">Select weekly menu</option>
              <option
                v-for="week in weeklyMenus"
                :key="week.id"
                :value="week">
                {{weekName(week.startDate, {weekFrom: false})}}
              </option>
            </select>
            <template v-if="$v.filter.weeklyMenu.$dirty">
              <div v-if="!$v.filter.weeklyMenu.required" class="invalid-feedback d-block">Weekly menu is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 form-label text-right">Customer delivery days</label>
          <div class="col-sm-7">
            <label
              v-for="date in deliveryDays"
              :key="date.id"
              class="form-check form-check-inline">
              <input
                class="form-check-input"
                :checked="!!$v.filter.days.$model.find(item => item.id === date.id)"
                type="checkbox"
                @change="toggleArray($v.filter.days.$model, date, (array, selected) => array.id === selected.id)">
              <span class="form-check-label">{{date.name}}</span>
            </label>
            <template v-if="$v.filter.days.$dirty">
              <div v-if="!$v.filter.days.required" class="invalid-feedback d-block">Days is required</div>
            </template>
          </div>
        </div>
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label form-label text-right">Purchase Order Type</label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.POType.$model"
              class="form-control custom-select"
              :class="{'is-invalid': $v.filter.POType.$error}">
              <option :value="null">Select Purchase Order Type</option>
              <option
                v-for="typeText in POTypes"
                :key="typeText"
                :value="typeText">
                {{typeText}}
              </option>
            </select>
            <template v-if="$v.filter.POType.$dirty">
              <div v-if="!$v.filter.POType.required" class="invalid-feedback d-block">Purchase Order is required</div>
            </template>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-auto">
            <button class="form-control btn btn-primary">Next</button>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <form-description label="Weekly menu" :value="weekName(filter.weeklyMenu.startDate, {weekFrom: false})"/>
      <form-description label="Customer delivery days" :value="filter.days.map(day => day.name).join(', ')"/>
      <form-description label="Purchase Order Type" :value="filter.POType"/>
    </template>
  </div>
</template>

<script>
import {deliveryDays, weeklyMenusBasic} from '@/services';
import {required} from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
import {thursdayBasedWeek} from '@hellochef/shared-js/helpers';
import FormDescription from '@/components/FormDescription';
import POTypes from '@/assets/enums/POTypes';

export default {
  components: {

    FormDescription,
  },
  props: {

    edit: {

      default: true,
      type: Boolean,
    },
    filter: {

      required: true,
      type: Object,
    },
    viewOnly: {

      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      POTypes,
      weeklyMenus: [],
      deliveryDays: [],
    };
  },
  created() {
    deliveryDays.getByParameters({column: 'id', direction: 'asc'}).then(result => this.deliveryDays = result.items);

    weeklyMenusBasic.getByParameters({
      column: 'id',
      direction: 'desc',
      limit: 25,
      startDateFrom: thursdayBasedWeek(moment()).format('YYYY-MM-DD'),
      status: ['published', 'scheduled'],
    })
      .then(result => {
        this.weeklyMenus = result.items;

        const startWeek = this.getPurchaseStartWeek();

        // set default earliest week available
        this.$v.filter.weeklyMenu.$model = this.weeklyMenus[this.weeklyMenus.length - startWeek];
      });

    // set default Manual PO
    this.$v.filter.POType.$model = 'Manual';
  },
  methods: {
    validate() {
      this.$v.filter.$touch();

      if (!this.$v.filter.$invalid) {
        this.$emit('valid', this.filter);
      }
    },
    getPurchaseStartWeek() {
      // by default they need the current week for thursday = 4 and friday = 5 only
      const daysForCurrentWeek = ['4', '5'];

      // if it's not thursday or friday we should grab next week as default
      return daysForCurrentWeek.includes(moment().format('d')) ? 1 : 2;
    },
  },

  validations: {
    filter: {
      days: {

        required: value => value.length,
      },
      weeklyMenu: {

        required,
      },
      POType: {

        required,
      },
    },
  },

};
</script>
